"use client";

import ErrorPage from "@/components/layout/ErrorPage";
import errorTracker from "@/lib/errorTracker";
import Image from "next/image";
import { useEffect } from "react";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    errorTracker.sendError(error);
  }, [error]);

  return (
    <html lang="en">
      <body>
        <ErrorPage
          title="Something went very wrong"
          message="A critical error occurred. Please try again later or contact support."
          icon={<Image src="/images/bad-link-circle.svg" alt="Error" width={200} height={200} />}
        />
      </body>
    </html>
  );
}
